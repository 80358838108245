/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import jwt from "../../http/requests/auth/jwt/index.js";
import axios from "@/axios.js";

// import firebase from 'firebase/app'
import "firebase/auth";
//import router from "@/router";
export default {
  // JWT
  loginJWT({ commit }, payload) {
    // 
    return new Promise((resolve, reject) => {
       
      jwt
        .login(
          payload.userDetails.email,
          payload.userDetails.password,
          payload.userDetails.Language
        )
        .then(response => {
          if(response.data.StatusCode==201){
            resolve(response);

          }
          // If there's user data in response
         else if (response.data) {
            // Navigate User to homepage
            //   router.push(router.currentRoute.query.to || '/')

            // Set token
             debugger
            localStorage.setItem("token", response.data.data.accessToken);
            localStorage.setItem("expiresIn", response.data.data.expiresIn);
            localStorage.setItem("accessToken", response.data.data.accessToken);
            localStorage.setItem("screenRolesPermissions",JSON.stringify( response.data.data.screenRolesPermissions));

            localStorage.setItem(
              "refreshToken",
              response.data.data.refreshToken
            );
            if (response.data.data.userData.teacherData != null) {
              response.data.data.userData.TeacherID =
                response.data.data.userData.teacherData.ID;
            }
            // Update user details
            commit("UPDATE_USER_INFO", response.data.data.userData, {
              root: true
            });

            // Set bearer token in axios
            commit("SET_BEARER", response.data.data.accessToken);
            //slocalStorage.setItem("TypeOfLogin", payload.TypeOfLogin)
            // to call local sotrage
            //localStorage.getItem('TypeOfLogin')
            resolve(response.data);
          } else {
            reject({ message: "Wrong Email or Password" });
          }
        })
        .catch(error => {
          // 
          reject(error);
        });
    });
  },
  ChangeParentActiveStudent({ commit }, id) {
     
    return new Promise((resolve, reject) => {
      axios
        .get("/API/Account/ChangeRelatedUserActiveStatus?relatedUserID=" + id)
        .then(response => {
           
          console.log(response);
          resolve(response);
          if (response.data) {
            // Navigate User to homepage
            //   router.push(router.currentRoute.query.to || '/')

            // Set token
             

            // Update user details
            commit("UPDATE_USER_INFO", response.data.data.userData, {
              root: true
            });

            // Set bearer token in axios
            // commit("SET_BEARER", response.data.data.accessToken);
            //slocalStorage.setItem("TypeOfLogin", payload.TypeOfLogin)
            // to call local sotrage
            //localStorage.getItem('TypeOfLogin')
            resolve(response.data);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  ChangeUserLanguage({ commit }, languageId) {
    return new Promise((resolve, reject) => {
       
      jwt
        .ChangeUserLanguage(languageId)
        .then(response => {
          if (response.data) {
             
            localStorage.setItem("token", response.data.data.accessToken);
            localStorage.setItem("expiresIn", response.data.data.expiresIn);
            localStorage.setItem("accessToken", response.data.data.accessToken);
            localStorage.setItem(
              "refreshToken",
              response.data.data.refreshToken
            );
            commit("UPDATE_USER_INFO", response.data.data.userData, {
              root: true
            });
            commit("SET_BEARER", response.data.data.accessToken);
            resolve(response.data);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // registerUserJWT({ commit }, payload) {
  //   const {
  //     displayName,
  //     email,
  //     password,
  //     confirmPassword
  //   } = payload.userDetails;

  //   return new Promise((resolve, reject) => {
  //     // Check confirm password
  //     if (password !== confirmPassword) {
  //       reject({ message: "Password doesn't match. Please try again." });
  //     }

  //     jwt
  //       .registerUser(displayName, email, password)
  //       .then(response => {
  //         // Redirect User
  //         router.push(router.currentRoute.query.to || "/");

  //         // Update data in localStorage
  //         localStorage.setItem("token", response.data.token);
  //         commit("UPDATE_USER_INFO", response.data, { root: true });

  //         resolve(response);
  //       })
  //       .catch(error => {
  //         reject(error);
  //       });
  //   });
  // },

  fetchAccessToken() {
    // 
    return new Promise(resolve => {
      jwt
        .refreshToken()
        .then(response => {
          resolve(response);
        })
        .catch(function() {
          // 
          //router.push("/pages/login");
        });
    });
  },
  UpdateFCMToken({commit} ,token){
    debugger
    var model = {};
    model.refreshToken = localStorage.getItem("refreshToken");
    model.FirebaseToken = token;
    return new Promise((resolve) => {
      axios
        .post("/API/Account/Refresh",model)
        .then(response => {
          if (response.data) {
             
            localStorage.setItem("token", response.data.data.accessToken);
            localStorage.setItem("expiresIn", response.data.data.expiresIn);
            localStorage.setItem("accessToken", response.data.data.accessToken);
            localStorage.setItem(
              "refreshToken",
              response.data.data.refreshToken
            );           
            commit("SET_BEARER", response.data.data.accessToken);
            resolve(response.data);
          }
        })
    });
  },
  logout({ commit }) {
    // Set accessToken
    localStorage.removeItem("accessToken");
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("expiresIn");
    // Update user details
    commit("REMOVE_USER_STORE", null, { root: true });
    commit("CLEAR_USER_INFO", null, { root: true });
    // Set bearer token in axios
    commit("REMOVE_BEARER");
  },
  ChangePassword(context, model) {
    return new Promise((resolve, reject) => {
       
      axios
        .post("/ChangePassword", model)
        .then(response => {
           
          console.log(response);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  SendVerifyCodeForPhone(context, PhoneNumber) {
    return new Promise((resolve, reject) => {
       
      axios
        .post(
          "/api/Account/SendVerifyCodeForPhone?PhoneNumber=" +
            PhoneNumber +
            "&isResetPassword=" +
            true
        )
        .then(response => {
           
          console.log(response);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  CheckCode(context, model) {
    return new Promise((resolve, reject) => {
       
      axios
        .post(
          "/api/Account/CheckCode?PhoneNumber=" +
            model.PhoneNumber +
            "&Code=" +
            model.Code
        )
        .then(response => {
           
          console.log(response);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  ResetPassword(context, model) {
    return new Promise((resolve, reject) => {
       
      axios
        .post(
          "/api/Account/ResetPassword?phoneNumber=" +
            model.phoneNumber +
            "&code=" +
            model.code +
            "&newPassword=" +
            model.newPassword
        )
        .then(response => {
           
          console.log(response);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  GetUser(context, itemid) {
    return new Promise((resolve, reject) => {
      axios
        .get("/API/Account/GetUser?userID=" + itemid)
        .then(response => {
          resolve(response);

          // commit('UPDATE_QuestionBank', response.data.data)
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  EditProfile(context, itemid) {
    return new Promise((resolve, reject) => {
      axios
        .post("API/Account/EditProfile", itemid)
        .then(response => {
          resolve(response);

          // commit('UPDATE_QuestionBank', response.data.data)
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  //notification
  CreateMeeting(context, Model) {
    return new Promise((resolve, reject) => {
      debugger;
      axios
        .post("/Meeting/CreateMeeting", Model)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  CreateTestMeeting(context, Model) {
    return new Promise((resolve, reject) => {
      debugger;
      axios
        .post("/Meeting/CreateTestMeeting", Model)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  GetUserMeetingsList() {
    return new Promise((resolve, reject) => {
      axios
        .get("/Meeting/GetUserMeetingsList")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  GetUserMeetingsHistoryList() {
    return new Promise((resolve, reject) => {
      axios
        .get("/Meeting/GetUserMeetingsHistoryList")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  GetUserMeetingsTestHistoryList() {
    return new Promise((resolve, reject) => {
      axios
        .get("/Meeting/GetUserMeetingsTestHistoryList")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  GetUserMeetingsTestList() {
    return new Promise((resolve, reject) => {
      axios
        .get("/Meeting/GetUserMeetingsTestList")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  GetALLStudentWillCommingMeetings() {
    return new Promise((resolve, reject) => {
      axios
        .get("/Meeting/GetALLStudentWillCommingMeetings")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  GetALLHistoryStudentMeetings() {
    return new Promise((resolve, reject) => {
      axios
        .get("/Meeting/GetALLHistoryStudentMeetings")
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  GetRecordings(context, meetingId) {
    return new Promise((resolve, reject) => {
      axios
        .get("/Meeting/GetRecordings", {
          params: {
            meetingId: meetingId
          }
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  JoinMeeting(context, Model) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .get("/Meeting/JoinMeeting", {
          params: {
            password: Model.password,
            meetingID: Model.meetingID
          }
        })
        .then(response => {
          if (response.status == 200) {
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  JoinTestMeeting(context, Model) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .get("/Meeting/JoinTestMeeting", {
          params: {
            password: Model.password,
            meetingID: Model.meetingID
          }
        })
        .then(response => {
          if (response.status == 200) {
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  GetAllNewNotification({ commit }) {
    commit("RemoveFrom_AllNotificationList", [], { root: true });
    return new Promise((resolve, reject) => {
      axios
        // .get(`/Notification/GetAllNewNotification`)
        .get(`GetUserNotification`)
        .then(response => {
           debugger
           if(response.data.data!=null&&response.data.data!=undefined&&response.data.data.length>0)
         {
          response.data.data.forEach(element => {
            commit("UPDATE_NotificationList", element, { root: true });
          });
         }
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  MarkIsReadNotification(context, ID) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/API/Notification/setNotificationAsRead?id=${ID}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
  // MarkIsNewNotificationByNotify(context, ID) {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .get(`/Notification/MarkIsNewNotificationByNotify?ID=${ID}`)
  //       .then(response => {
  //         resolve(response);
  //       })
  //       .catch(error => {
  //         reject(error);
  //       });
  //   });
  // },
  // DeleteAllNotification() {
  //   return new Promise((resolve, reject) => {
  //     axios
  //       .get(`/Notification/DeleteAllNotification`)
  //       .then(response => {
  //         resolve(response);
  //       })
  //       .catch(error => {
  //         reject(error);
  //       });
  //   });
  // }
};
