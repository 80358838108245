import Vue from "vue";
import { AclInstaller, AclCreate, AclRule } from "vue-acl";
import router from "./../router";

Vue.use(AclInstaller);
let initialRole = "anonymous";
let userInfo = JSON.parse(localStorage.getItem("userInfo"));
// alert(userInfo.UserType);
if (userInfo && userInfo.UserType) {
  if(userInfo.UserType =="Admin" 
  ||userInfo.UserType =="Expert" 

  ){
    initialRole = userInfo.UserType;

  }
  else{
  initialRole = "anonymous";

  }
}
else {
  initialRole = "anonymous";
}
export default new AclCreate({
  initial: initialRole,
  notfound: {
    path: "/pages/error-404",
    forwardQueryParams: true
  },
  router,
  acceptLocalRules: true,
  globalRules: {
    User: new AclRule("User")
      .or("User")
      .or("Admin")
      .generate(),
    Admin: new AclRule("Admin").or("admin").generate(),
    Expert: new AclRule("Expert").or("expert").generate(),

    AdminExpert: new AclRule("anonymous")
      .or("Expert")
      .or("editor")
      .or("Admin")
      .or("admin")
      .generate(),
    anonymous: new AclRule("anonymous")
      .or("Expert")
      .or("editor")
      .or("Admin")
      .or("admin")
      .generate(),
   
  },
  middleware: async acl => {
    // alert(initialRole);
    acl.change(initialRole);
  }
});
