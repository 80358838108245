<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import themeConfig from "@/../themeConfig.js";
import jwt from "@/http/requests/auth/jwt/index.js";
import router from "@/router";
import { Validator } from "vee-validate";
import firebase from "firebase/app";
import "firebase/messaging";
import ar from "vee-validate/dist/locale/ar";
import en from "vee-validate/dist/locale/en";
import fr from "vee-validate/dist/locale/fr";
// Install the Plugin.
import moment from "moment";
 import { mainSetting } from '@/gloabelConstant.js'
export default {
  data() {
    return {
      CurrentStatus: null
    };
  },
  watch: {
    userRole(val) {
      alert("FFFFF" + val);
      this.$store.dispatch("updateUserRole", {
        aclChangeRole: this.$acl.change,
        userRole: val
      });
    },
    "$store.state.theme"(val) {
      this.toggleClassInBody(val);
    },
    "$vs.rtl"(val) {
      document.documentElement.setAttribute("dir", val ? "rtl" : "ltr");
    },
    "$i18n.locale"(val) {
      switch (val) {
        case "ar":
          Validator.localize("ar", ar);
          break;

        case "en":
          Validator.localize("en", en);

          break;
        case "fr":
          Validator.localize("fr", fr);

          break;
        default:
          Validator.localize("en", en);
          break;
      }
    }
  },
  methods: {
    changeFormatDate(date){
        return moment(String(date)).format("DD/MM/YYYY HH:mm:ss")
    },
    StartLoading() {
      this.$vs.loading();
    },
    CloseLoading() {
      this.$vs.loading.close();
    },
    /* eslint-disable */
    checkAndAttachMapScriptGoogleMaps(callback) {
      if (!!window.google) {
        // If you're using vue cli, then directly checking
        // 'google' obj will throw an error at the time of transpiling.
        callback();
        return true;
      }
      window.mapApiInitialized = callback;
      let script = document.createElement("script");
      let language = this.$i18n.locale;
      let region = JSON.parse(
        localStorage.getItem("userInfo")
      ).countrycode.toUpperCase();
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAFu68ylJSETtU0lF9sWIj3bh524ntyImQ&hl=ar&libraries=places,geometry&language=${language}
      &region=${region}&callback=mapApiInitialized`;
      script.defer = true;
      script.async = true;
      document.body.appendChild(script);
    },

    checkAndAttachMapScript() {
      let initialized = !!window.google;
      return new Promise(async (resolve, reject) => {
        if (initialized) {
          return resolve(window.google);
        }

        initialized = true;
        window.mapApiInitialized = () => resolve(window.google);
        let language = await this.$i18n.locale;
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAFu68ylJSETtU0lF9sWIj3bh524ntyImQ&libraries=places,geometry&language=${language}&callback=mapApiInitialized`;
        script.async = true;
        script.defer = true;
        script.onload = () => {
          resolve(window.google);
        };
        // script.onerror = () => reject(new Error("Script load error: " + src));
        document.body.appendChild(script);
      });
    },
    /* eslint-enable */
    /* eslint-enable */
    FcmStart() {
      debugger
      var config = {
         apiKey: "AIzaSyDFAIK50JcGmfK6YEHAIiYELTyWGwRW9T4",
  authDomain: "schoolu-3b13c.firebaseapp.com",
  projectId: "schoolu-3b13c",
  storageBucket: "schoolu-3b13c.appspot.com",
  messagingSenderId: "599672374639",
  appId: "1:599672374639:web:ebf6812d40489c5dd0149a",
  measurementId: "G-DM77GMTPHG"
      };
      firebase.initializeApp(config);

      var messaging = firebase.messaging();

      messaging.usePublicVapidKey(
        "BMGm4KMbHweTxIL3WDenU8F44Z5Az6HIZsF2umXyCdzCfg3xSih6nhmVNQqCj1efR_ztums9x2O-s-P_YtOMA7M"
      );
navigator.serviceWorker.register('firebase-messaging-sw.js').then(function(registration) {
    console.log('Service Worker Registered!', registration);
}).catch(function(err) {
    console.error('Service Worker registration failed', err);
});
        messaging.getToken().then(token => {
            debugger
            messaging.onMessage(
              payload => {
                console.log(payload);
                switch (payload.data.controller) {
                  case "logout":
                    this.$store.dispatch("auth/logout");

                    break;
                  case "reset":
                    location.reload("/");

                    break;

                  // case "changeCountry":
                  //   break;
                  case "GoTo":
                    this.$router.push({ name: payload.data.routeName });

                    break;
                }
                if (!payload.data.controller) {
                  this.$store.commit("UPDATE_NotificationList", payload);
                }
              },
              e => {
                console.log(e);
              }
            );
            
            mainSetting.FCMToken=token;
            console.log(token)
            this.saveNotificationToken(token);
          });

      // messaging
      //   .requestPermission()
      //   .then(() => {
        
      //   })
      //   .catch(err => {
      //     console.log("Unable to get permission to notify.", err);
      //   });
    },
    saveNotificationToken(token) {
      this.$store.dispatch("auth/UpdateFCMToken", token);
    },

    showAddSuccess() {
      this.$vs.notify({
        color: "success",
        position: "bottom-center",
        title: this.$t("Success"),
        text: this.$t("CreatedSuccessfully")
      });
    },
    showUpdateSuccess() {
      this.$vs.notify({
        color: "success",
        position: "bottom-center",
        title: this.$t("Success"),
        text: this.$t("UpdatedSuccessfully")
      });
    },
    showAddFailed(err) {
      if (err != "") {
        this.$vs.notify({
          color: "success",
          position: "bottom-center",
          title: this.$t("Error"),
          text: err
        });
      } else {
        this.$vs.notify({
          color: "success",
          position: "bottom-center",
          title: this.$t("Error"),
          text: this.$t("CreatedFailed")
        });
      }
    },
    showUpdateFailed(err) {
      if (err != "") {
        console.log(err);
        this.$vs.notify({
          color: "danger",
          position: "bottom-center",
          title: this.$t("Error"),
          text: err
        });
      } else {
        console.log(err);
        this.$vs.notify({
          color: "danger",
          position: "bottom-center",
          title: this.$t("Error"),
          text: this.$t("UpdatedFailed")
        });
      }
    },
    showDeleteSuccess() {
      this.$vs.notify({
        color: "success",
        position: "bottom-center",
        title: this.$t("Success"),
        text: this.$t("DeletedSuccessfully")
      });
    },
    showDeleteFailed(err) {
      if (err != "") {
        this.$vs.notify({
          color: "danger",
          position: "bottom-center",
          title: this.$t("Error"),
          text: err
        });
      } else {
        this.$vs.notify({
          color: "danger",
          position: "bottom-center",
          title: this.$t("Error"),
          text: this.$t("DeleteFailed")
        });
      }
    },
    showFailed() {
      this.$vs.notify({
        color: "danger",
        position: "bottom-center",
        title: this.$t("Error"),
        text: this.$t("ErrorTryLater")
      });
    },

    showFillData() {
      this.$vs.notify({
        color: "danger",
        position: "right-bottom",
        text: "Please fill all required data..."
      });
    },
    showError() {
      this.$vs.notify({
        color: "danger",
        position: "right-bottom",
        title: this.$t("Error"),
        text: this.$t("showError")
      });
    },
    showSuccessCreate() {
      this.$vs.notify({
        color: "primary",
        position: "top-center",
        title: this.$t("Create"),
        text: this.$t("RegisterCreatedSuccessfuly")
      });
    },
    showAlreadyExist() {
      this.$vs.notify({
        color: "warning",
        position: "bottom-center",
        title: this.$t("AlreadyExist"),
        text: this.$t("ThisItemAlreadyExist")
      });
    },
    showAddDriverToBiadjoSuccess() {
      this.$vs.notify({
        color: "primary",
        position: "top-center",
        title: this.$t("AssignToDriver"),
        text: this.$t("Assign")
      });
    },
    showSuccess() {
      this.$vs.notify({
        color: "primary",
        position: "top-center",
        title: this.$t("Update"),
        text: this.$t("showUpdate")
      });
    },
    showSuccessAddNote() {
      this.$vs.notify({
        color: "primary",
        position: "top-center",
        title: this.$t("Update"),
        text: this.$t("SuccessAddNote")
      });
    },
    showDriverAddeddSuccessfuly() {
      this.$vs.notify({
        color: "primary",
        position: "top-center",
        title: this.$t("Success"),
        text: this.$t("DriverAddeddSuccessfuly")
      });
    },
    showNoRecords() {
      this.$vs.notify({
        color: "warning",
        position: "bottom-center",
        title: this.$t("Search"),
        text: this.$t("showNoDataAvailable")
      });
    },
    showCheckEmail() {
      this.$vs.notify({
        color: "primary",
        position: "top-center",
        title: this.$t("ResetPassword"),
        text: this.$t("showCheckEmail")
      });
    },
    showPasswordChanged() {
      this.$vs.notify({
        color: "primary",
        position: "top-center",
        title: this.$t("ResetPassword"),
        text: this.$t("showPasswordChanged")
      });
    },
    notAllowedToAccessCurrentPage(CurrentStatus) {
      if (
        CurrentStatus == "BS11" ||
        CurrentStatus == "BS13" ||
        CurrentStatus == "BS14" ||
        CurrentStatus == "BS15" ||
        CurrentStatus == "BS16" ||
        CurrentStatus == "SO3" ||
        CurrentStatus == "SO4" ||
        CurrentStatus == "CS2" ||
        CurrentStatus == "CS3" ||
        CurrentStatus == "PS1" ||
        CurrentStatus == "DPS1"
      ) {
        this.$router.go(-1);
      }
    },
    InvalidImageType() {
      this.$vs.notify({
        color: "warning",
        position: "bottom-center",
        title: this.$t("InvalidType"),
        text: this.$t("InvalidImageType")
      });
    },
    toggleClassInBody(className) {
      if (className == "dark") {
        if (document.body.className.match("theme-semi-dark")) {
          document.body.classList.remove("theme-semi-dark");
        }
        document.body.classList.add("theme-dark");
      } else if (className == "semi-dark") {
        if (document.body.className.match("theme-dark")) {
          document.body.classList.remove("theme-dark");
        }
        document.body.classList.add("theme-semi-dark");
      } else {
        if (document.body.className.match("theme-dark")) {
          document.body.classList.remove("theme-dark");
        }
        if (document.body.className.match("theme-semi-dark")) {
          document.body.classList.remove("theme-semi-dark");
        }
      }
    },
    handleWindowResize() {
      this.$store.commit("UPDATE_WINDOW_WIDTH", window.innerWidth);
    },
    handleScroll() {
      this.$store.commit("UPDATE_WINDOW_SCROLL_Y", window.scrollY);
    }
  },
  mounted() {
    
    var color = "228, 228, 228";
    if (JSON.parse(localStorage.getItem("userInfo")).UserType == "Teacher") {
      color = "97, 117, 226";
    } else if (
      JSON.parse(localStorage.getItem("userInfo")).UserType == "Parent"
    ) {
      color = "255, 78, 78";
    } else {
      color = "228, 228, 228";
    }

    document.documentElement.style.setProperty("--vs-menuColor", color);
    this.toggleClassInBody(themeConfig.theme);
    this.$store.commit("UPDATE_WINDOW_WIDTH", window.innerWidth);
    this.toggleClassInBody(this.$store.state.theme);
    this.$store.commit("UPDATE_WINDOW_WIDTH", window.innerWidth);
    if (this.$store.state.auth.isUserLoggedIn()) {
      this.$store.dispatch("auth/GetAllNewNotification");
      debugger
      if (Notification.permission == "granted") {
        this.FcmStart();
      }
      if (Notification.permission == "default") {
        // If it's okay let's create a notification
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t("Notification"),
          text: this.$t("PleaseAllowNotification"),
          accept: this.FcmStart,
         
          cancelText: this.$t("Cancel"),
          acceptText: this.$t("Save")
        });
      }
      // if (Notification.permission === "denied") {
      //   // If it's okay let's create a notification
      //   this.$vs.notify({
      //     title: this.$t("Notification"),
      //     text: this.$t("PleaseAllowNotification"),
      //     color: "warning",
      //     position: "top-center",
      //     iconPack: "feather",
      //     icon: "icon-bell"
      //   });
      // }
    }
  },
  async created() {
    //this.$store.commit("UpdateSessionExpired", false);

    // jwt
    jwt.init();
debugger;
    if (this.$store.state.auth.isUserLoggedIn() || window.location.hash=="#/LiveSessions" || window.location.hash=="#/LiveSessionAttandance") {
      // this.$store
      //   .dispatch("auth/refreshCurrentUser")
      //   .then(response => {
      //     if (response.data.status == "AS1") {
      //       this.$acl.change(response.data.accounttype);
      //     }
      //     if (response.data.status != "AS1") {
      //       debugger;
      //       this.$store.commit("CLEAR_USER_INFO", null, { root: true });
      //       this.$store.commit("REMOVE_USER_STORE", null, { root: true });
      //       this.$store.dispatch("auth/logout");
      //       router.push("/pages/login").catch(() => {});
      //     }
      //   })
      //   .catch(err => {
      //     console.log(err);
      //     debugger;
      //     this.$store.commit("CLEAR_USER_INFO", null, { root: true });
      //     this.$store.commit("REMOVE_USER_STORE", null, { root: true });
      //     this.$store.dispatch("auth/logout");
      //   });
    } else {

       router.push("/pages/login").catch(() => {});
    }

    if (localStorage.getItem("SaveLang") === "ar") {
      this.$vs.rtl = true;
    }
    
if(localStorage.getItem("screenRolesPermissions")===null||localStorage.getItem("screenRolesPermissions")===undefined){
  // localStorage.getItem("screenRolesPermissions") = [];
            localStorage.setItem("screenRolesPermissions",JSON.stringify( []));

}
    window.changeFormatDate = this.changeFormatDate;
    window.showAddSuccess = this.showAddSuccess;
    window.showUpdateSuccess = this.showUpdateSuccess;
    window.showAddFailed = this.showAddFailed;
    window.showUpdateFailed = this.showUpdateFailed;
    window.showDeleteSuccess = this.showDeleteSuccess;
    window.showDeleteFailed = this.showDeleteFailed;
    window.showFailed = this.showFailed;
    window.StartLoading = this.StartLoading;
    window.CloseLoading = this.CloseLoading;
    window.showError = this.showError;
    window.InvalidImageType = this.InvalidImageType;
    window.showFillData = this.showFillData;
    window.showSuccess = this.showSuccess;
    window.showAddDriverToBiadjoSuccess = this.showAddDriverToBiadjoSuccess;
    window.showNoRecords = this.showNoRecords;
    window.showCheckEmail = this.showCheckEmail;
    window.showPasswordChanged = this.showPasswordChanged;
    window.notAllowedToAccessCurrentPage = this.notAllowedToAccessCurrentPage;
    window.showSuccessCreate = this.showSuccessCreate;
    window.showAlreadyExist = this.showAlreadyExist;
    window.checkAndAttachMapScript = this.checkAndAttachMapScript;
    window.showDriverAddeddSuccessfuly = this.showDriverAddeddSuccessfuly;
    window.showSuccessAddNote = this.showSuccessAddNote;
    window.checkAndAttachMapScriptGoogleMaps = this.checkAndAttachMapScriptGoogleMaps;

    let dir = this.$vs.rtl ? "rtl" : "ltr";
    document.documentElement.setAttribute("dir", dir);

    window.addEventListener("resize", this.handleWindowResize);
    window.addEventListener("scroll", this.handleScroll);
    this.$emit("updateNavbarColor", "darkturquoise");
    // this.$vs.theme({ primary: '#f1c717' });
    this.$vs.theme({ primary: "#3268a8" });
  },
  destroyed() {
    window.removeEventListener("resize", this.handleWindowResize);
    window.removeEventListener("scroll", this.handleScroll);
  }
};
</script>
<style scoped>
/* .vs-navbar {
    background-image: url("assets/images/pages/navbarheader.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
} */

#loading-bg {
  width: 100%;
  height: 100%;
  background: #fff;
  display: block;
  position: absolute;
}

.loading-logo {
  position: absolute;
  left: calc(50% - 45px);
  top: 35%;
}

.loading {
  position: absolute;
  left: calc(50% - 35px);
  top: 50%;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 3px solid transparent;
}

.loading .effect-1,
.loading .effect-2 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid rgba(121, 97, 249, 1);
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effect-1 {
  animation: rotate 1s ease infinite;
}

.loading .effect-2 {
  animation: rotateOpacity 1s ease infinite 0.1s;
}

.loading .effect-3 {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-left: 3px solid rgba(121, 97, 249, 1);
  -webkit-animation: rotateOpacity 1s ease infinite 0.2s;
  animation: rotateOpacity 1s ease infinite 0.2s;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.loading .effects {
  transition: all 0.3s ease;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes rotateOpacity {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    opacity: 0.1;
  }
  100% {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
    opacity: 1;
  }
}
</style>
