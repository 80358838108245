// en, de, fr, pt
export default {
  en: {
    DescriptionOfAttachment: "Description Of Attachment",
    LoginAttempt: "Login Attempt",
    Youarealreadyloggedin: "You are already logged in!",
    ProjectTitle: "TikiTka",
    AddNew: "Add New",
    Action: "Action",
    NameAr: "Arabic Name",
    NameEn: "English Name",
    CommingMeetings: "Comming Meetings",
    ClassName: "Class Name",
    CreatorName: "Creator Name",
    FixedExam: "Fixed Exam",
    RandomExam: "Random Exam",
    PleaseSelectAbsenceStudents: "Please Select On Absence Students",
    TotalAttendees: "Total Attendees",
    TotalAbsence: "Total Absence",
    MeetingsList: "Meetings List",
    IsPublic: "Is Public",
    HasRecord: "Has Record",
    StudentMeetingsList: "Student Meetings List",
    showNoDataAvailable: "No data available... Please try again",
    NoNotesAvailable: "No notes available",
    SorryYouAccountisNotActive: "Sorry..You account is not active",
    WelcomeMsg: "Welcome back, Please login to your account.",
    Update: "Update",
    showError: "Something went wrong... Please try again",
    showUpdate: "Record updated successfully...",
    showCheckEmail: "Kindly check you email for new password...",
    showPasswordChanged: "Password changed...",
    NameFr: "French Name",
    AddressAr: "Arabic Adress",
    AddressEn: "English Adress",
    AddressFr: "French Adress",
    NoteAr: "Arabic Note",
    NoteEn: "English Note",
    NoteFr: "French Note",
    AboutUsAr: "Arabic AboutUs",
    AboutUsEn: "English AboutUs",
    AboutUsFr: "French AboutUs",
    Logo: "Logo",
    SendVertificationCode: "Send Vertification Code",
    Cancel: "Cancel",
    Delete: "Delete",
    PhoneNumber: "Phone Number",
    VertificationCode: "Vertification Code",
    Vertify: "Vertify",
    MainInfo: "Main Info",
    LoginInfo: "Login Info",
    Save: "Save",
    Email: "Email",
    Password: "Password",
    Bus: "Bus",
    Buses: "Buses",
    Attachment: "Attachment",
    Installments: "Installments",
    Installment: "Installment",
    Stage: "Stage",
    StageYear: "Stage Year",
    Class: "Class",
    Age: "Age",
    Subjects: "Subjects",
    NewAttachment: "New Attachment",
    AddAttachment: "Add Attachment",
    Description: "Description",
    URL: "URL",
    File: "File",
    NewBus: "New Bus",
    AddBus: "Add Bus",
    ModelAr: "Arabic Model",
    ModelEn: "English Model",
    ModelFr: "French Model",
    IsActive: "Active",
    AddInstallment: "Add Installment",
    NewInstallment: "New Installment",
    InstallmentTypeEn: "Installment Type English NAme",
    PayDate: "Pay Date",
    IsPaid: "Paid",
    Routes: "Routes",
    Users: "Users",
    Students: "Students",
    Teachers: "Teachers",
    Drivers: "Drivers",
    StudentExam: "Student Test",
    StudentExamAnswer: "Student Test Answer",
    ExaminationManagement: "Examination Management",
    QuestionBank: "Question Bank",
    ExamRule: "Test Rule",
    ExamGeneration: "Test Generation",
    Setting: "Setting",
    Date: "Date",
    ClassSessionName: "Session",
    SubjectName: "Subject",
    FromDate: "From",
    ToDate: "To",
    ClassSession: "Session",
    ClassSchedule: "Class Schedule",
    ClassScheduleAddEdit: "Class Schedule Add/Edit",
    Status: "Status",
    SchoolInfo: "School Info",
    AssessmentType: "Assessment Type",
    BusSuperVisor: "Bus SuperVisor",
    Language: "Language",
    GradeType: "Grade Type",
    ExamType: "Test Type",
    QuestionLevel: "Question Level",
    QuestionType: "Question Type",
    Stages: "Stages",
    StageYears: "Stage Years",
    InstallmentType: "Installment Type",
    Name: "Name",
    Reset: "Reset",
    UserWithSamePhoneNumberExist: "User With Same Phone Number Exist",
    SomethingWentWrong: "Something Went Wrong",
    CodeIsNotExist: "Code Is Not Exist",
    Welcome: "Welcome",
    Modules: "Modules",
    Newlanguage: "New language",
    AddLanguage: "Add Language",
    NewModule: "New Module",
    AddModule: "Add Module",
    Module: "Module",
    Route: "Route",
    Details: "Details",
    AddRouteDetails: "Add Route Details",
    Longitude: "Longitude",
    Latitude: "Latitude",
    Region: "Region",
    NewRouteDetails: "New Route Details",
    Model: "Model",
    BusSupervisor: "Bus supervisor",
    Driver: "Driver",
    DriverAddEdit: "Driver Add/Edit",
    AddDriver: "Add Driver",
    NewDriver: "New Driver",
    Classes: "Classes",
    NewSubject: "New Subject",
    Subject: "Subject",
    Teacher: "Teacher",
    NewClass: "New Class",
    TeacherAddEdit: "Teacher Add/Edit",
    SubjectMaxScore: "Subject Max Score",
    AddSubject: "Add Subject",
    QuestionNum: "Question Number",
    QuestionAr: "Arabic Question",
    QuestionEn: "English Question",
    QuestionFr: "French Question",
    NewQuestion: "New Question",
    Question: "Question",
    Answers: "Answers",
    AnswerAr: "Arabic Answer",
    AnswerEn: "Englisg Answer",
    AnswerFr: "French Answer",
    IsRight: "IsRight",
    Addanswer: "Add Answer",
    DescriptionAr: "Arabic Description",
    DescriptionEn: "English Description",
    DescriptionFr: "French Description",
    Download: "Download",
    Type: "Type",
    AddRule: "Add Rule",
    NewRule: "New Rule",
    RuleInfo: "Rule Info",
    ExamStartDate: "Start Date",
    Exams: "Tests",
    TimeOfExamInMinutes: "Test Time In Minutes",
    Generate: "Generate",
    ExamMainInfo: "Test Main Info",
    GenerateQuestions: "Generate Questions",
    Text: "Text",
    TrueFalse: "True/False",
    MultiChoose: "Multi Choose",
    TimeInMinute: "Time In Minute",
    Weight: "Weight",
    TypeAr: "Arabic Type",
    TypeEn: "English Type",
    TypeFr: "French Type",
    UPDATE: "Update",
    Amount: "Amount",
    InstallmentDate: "Installment Date",
    DeadLine: "DeadLine",
    LevelAr: "Arabic Level",
    LevelEn: "English Level",
    LevelFr: "French Level",
    EventType: "Event Type",
    Events: "Events",
    SchoolArchitecture: "School Architecture",
    UserType: "User Type",
    RelatedAccounts: "Related Accounts",
    UserManagment: "User Managment",
    SubjectManagment: "Subject Managment",
    StageYearSubjects: "Stage Year Subjects",
    SubjectModules: "Subject Modules",
    BuseManagment: "Bus Managment",
    EventManagment: "Event Managment",
    InstallmentManagment: "Installment Managment",
    InstallmentYearStartDate: "Installment Year Start Date",
    InstallmentYearEndDate: "Installment Year End Date",
    Grade: "Grade",
    Active: "Active",
    Inactive: "Inactive",
    True: "True",
    False: "False",
    CustomUser: "Custom User",
    AllDriver: "All Driver",
    AllTeacher: "All Teacher",
    AllParent: "All Parent",
    StudentTeacherInstallments: "Student/Teacher Installments",
    YouAreAboutToAddThisAssessment: "You are about to add this assessment",
    ThisModuleAlreadyExists: "This module already exists",
    ThisSubjectAlreadyExists: "This subject already exists",
    ThisDriverAlreadyExists: "This driver already exists",
    Areyousureyouwantdeletethisrecord: "Are you sure you want delete this record",
    User: "User",
    Success: "Success",
    CreatedSuccessfully: "Created Successfully",
    UpdatedSuccessfully: "Updated Successfully",
    Error: "Error",
    CreatedFailed: "Created Failed",
    UpdatedFailed: "Updated Failed",
    DeletedSuccessfully: "Deleted Successfully",
    DeleteFailed: "Delete Failed",
    Confirm: "Confirm",
    DeleteConfirmText: "Are you sure you want delete this record?",
    ErrorTryLater: "SomeThing went wrong , please try later ..",
    AreyousureyouwantActivethisStudent: "Are you sure you want Active this Student?",
    Dashboard: "Dashboard",
    eCommerce: "eCommerce",
    Apps: "Apps",
    Todo: "Todo",
    Chat: "Chat",
    Calendar: "Calendar",
    FullCalendar: "Full Calendar",
    SimpleCalendar: "Simple Calendar",
    Shop: "Shop",
    Cart: "Cart",
    WishList: "Wish List",
    Checkout: "Checkout",
    UI: "UI",
    DataList: "Data List",
    ListView: "List View",
    ThumbView: "Thumb View",
    Grid: "Grid",
    Vuexy: "Vuexy",
    Vuesax: "Vuesax",
    Tailwind: "Tailwind",
    Colors: "Colors",
    Card: "Card",
    Basic: "Basic",
    Statistics: "Statistics",
    Analytics: "Analytics",
    CardActions: "Card Actions",
    CardColors: "Card Colors",
    Table: "Table",
    agGridTable: "agGrid Table",
    Components: "Components",
    Alert: "Alert",
    Avatar: "Avatar",
    Badge: "Badge",
    Breadcrumb: "Breadcrumb",
    Button: "Button",
    ButtonGroup: "Button Group",
    Chip: "Chip",
    Collapse: "Collapse",
    Dialogs: "Dialogs",
    Divider: "Divider",
    DropDown: "DropDown",
    List: "List",
    Loading: "Loading",
    Navbar: "Navbar",
    Notifications: "Notifications",
    Pagination: "Pagination",
    Popup: "Popup",
    Progress: "Progress",
    Sidebar: "Sidebar",
    Slider: "Slider",
    Tabs: "Tabs",
    Tooltip: "Tooltip",
    Upload: "Upload",
    FormsAndTable: "Forms & Table",
    FormElements: "Form Elements",
    Select: "Select",
    Switch: "Switch",
    Checkbox: "Checkbox",
    Radio: "Radio",
    Input: "Input",
    NumberInput: "Number Input",
    Textarea: "Textarea",
    FormLayouts: "Form Layouts",
    FormWizard: "Form Wizard",
    FormValidation: "Form Validation",
    FormInputGroup: "Form Input Group",
    Pages: "Pages",
    Authentication: "Authentication",
    Login: "Login",
    Register: "Register",
    ForgotPassword: "Forgot Password",
    ResetPassword: "Reset Password",
    ChangePassword: "Reset Password",

    LockScreen: "Lock Screen",
    Miscellaneous: "Miscellaneous",
    ComingSoon: "Coming Soon",
    404: "404",
    500: "500",
    NotAuthorized: "Not Authorized",
    Maintenance: "Maintenance",
    Profile: "Profile",
    Invoice: "Invoice",
    FAQ: "FAQ",
    Search: "Search",
    KnowledgeBase: "Knowledge Base",
    ChartsAndMaps: "Charts & Maps",
    Charts: "Charts",
    ApexCharts: "Apex Charts",
    chartjs: "chartjs",
    echarts: "echarts",
    chartist: "chartist",
    GoogleMap: "Google Map",
    Extensions: "Extensions",
    QuillEditor: "Quill Editor",
    DragAndDrop: "Drag & Drop",
    Datepicker: "Datepicker",
    DatetimePicker: "Datetime Picker",
    AccessControl: "Access Control",
    I18n: "I18n",
    Carousel: "Carousel",
    Clipboard: "Clipboard",
    ContextMenu: "Context Menu",
    StarRatings: "Star Ratings",
    Autocomplete: "Autocomplete",
    Tree: "Tree",
    Import: "Import",
    Export: "Export",
    ExportSelected: "Export Selected",
    Others: "Others",
    MenuLevels: "Menu Levels",
    MenuLevel2p1: "Menu Level 2.1",
    MenuLevel2p2: "Menu Level 2.2",
    MenuLevel3p1: "Menu Level 3.1",
    MenuLevel3p2: "Menu Level 3.2",
    DisabledMenu: "Disabled Menu",
    Support: "Support",
    Documentation: "Documentation",
    RaiseSupport: "Raise Support",
    demoTitle: "Card Title",
    demoText: "Cake sesame snaps cupcake gingerbread danish I love gingerbread. Apple pie pie jujubes chupa chups muffin halvah lollipop. Chocolate cake oat cake tiramisu marzipan sugar plum. Donut sweet pie oat cake dragée fruitcake cotton candy lemon drops.",
    /* custom i18n data */
    Course: "Course",
    CourseCatalog: "Course catalog",
    Instructor: "Instructors",
    Planning: "Planning",
    AnnualPlan: "Annual Plan",
    Facility: "Facility",
    ClassRoom: "Class Room",
    CourseType: "Course Type",
    CourseLevel: "Course Level",
    MaterialType: "Material Type",
    ViewMaterial: "View Material",
    PopulationList: "Population List",
    IndustrySector: "Industry Sector",
    AreaOfStudy: "Area Of Study",
    CourseCategory: "Course Category",
    Nationality: "Nationality",
    MaritalStatus: "Marital Status",
    ContactType: "Contact Type",
    EducationDegree: "Education Degree",
    Customer: "Customer",
    Trainee: "Trainee",
    UserManagement: "User Management",
    Home: "Home",
    YearSummary: "Year Summary",
    CourseClass: "Course Classes",
    QuestionManagement: "Question Management",
    Reports: "Reports",
    CourseRegistration: "Course Registrations",
    CourseRequest: "Course Requests",
    EnrollmentManagement: "Enrollment Management",
    TestManagement: "Test Management",
    CourseExam: "Course Exams",
    QuestionCategory: "Question Category",
    TermsOfUse: "Terms Of Use",
    PrivacyPolicy: "Privacy Policy",
    Imprints: "Imprints",
    NewMaterial: "New Material",
    StudentUserInstallment: "Student/User Installment",
    BusTracking: "Bus Tracking",
    All: "All",
    ChannelBook: "Channel Book",
    ChannelBookType: "Channel Book Type",
    ChannelBookAddEdit: "Channel Book Add/Edit",
    Sender: "Sender",
    Recevier: "Recevier",
    Message: "Message",
    MessageType: "MessageType",
    IsReplyable: "Is Replyable",
    BusSuperVisors: "Bus Super Visors",
    EndDate: "End Date",
    AddSubjects: "Add Subjects",
    ClassTeacherSubject: "Class Teacher Subject",
    Edit: "Edit",
    ThisItemAlreadyExist: "This Item Already Exist",
    AlreadyExist: "Already Exist",
    Material: "Material",
    Logout: "Logout",
    Pdf: "Pdf",
    Video: "Video",
    Image: "Image",
    Document: "Document",
    ExamCode: "Test Code",
    UpdateImage: "Update Image",
    RemoveImage: "Remove Image",
    WelcomeBack: "Welcome back, please login to your account.",
    RememberMe: "Remember Me",
    SendChannelBook: "Send channel book",
    Data: "Data",
    RelaredAccounts: "Relared accounts ",
    Filters: "Filters",
    StudentInstallmentCounts: "Student installment counts",
    ExamsAndEventsForWeek: "Tests and events for week",
    AllUsers: "All users",
    ExamsForWeek: "Tests for week",
    EventsForWeek: "Events for week",
    AlltInstallmentCounts: "All installment counts",
    PaidInstallmentCount: "Paid installment count",
    UnPaidInstallmentCount: "UnPaid installment count",
    AllChangeRouteRequest: "All change route request count",
    AllChangeRouteRequestPending: "change route request pending count",
    AllChangeRouteRequestRefused: "All change route request refused",
    AllChangeRouteRequestAccept: "All change route request accept",
    StudentBusOffForWeek: "Student bus off for week",
    studentBusOFF: "student number",
    AllRoutesAndBusses: "Routes and busses",
    StudentChangeRouteRequest: "StudentChangeRouteRequest",
    StudentChangeRouteRequestAddEdit: "Add/Edit",
    Accepted: "Accepted",
    AddEdit: "Add/Edit",
    ColorCode: "Color Code",
    SubjectExamRole: "Subject Test Role",
    BusTrips: "Bus Trips",
    BusTrip: "Bus Trip",
    TIMEOFSTART: "Time of start",
    TIMEOFEND: "Time of end",
    PickupLocation: "Adress",
    isInTrip: "is in trip ?",
    DaysOfWeek: "Day",
    maxScore: "max score",
    PasswordUpdated: "Password updated",
    SaveChanges: "Save Changes",
    YourPasswordHasBeenUpdatedSuccessfully: "Your Password Has Been Updated Successfully",
    Recoveryourpassword: "Recover your password",
    "Pleaseenteryouremailaddressandwe'llsendyouInstructionsOnHowtoresetyourpassword": "Please enter your email address and we'll send you Instructions On How to reset your password",
    BackToLogin: "Back To Login",
    sendcodetomail: "send code to phone",
    "pleasecheckyoure-mailanconfirmationsmssenttoyou": "please check your phone a confirmation sms sent to you",
    NumberOfQuestions: "Number Of Questions",
    SubjectStageYear: "Subject Stage Year",
    TeacherStudentsExamMarks: "Test Marks and Correct",
    ExamGrade: "Test Grade",
    StudentGrade: "Student Grade",
    Assessment: "Students Assessment",
    TrueAndFalse: "True And False",
    ShowWithAnswers: "Correct Answers",
    For: "For",
    Performance: "Performance",
    OverAll: "Over All",
    OverAllPerformance: "Over All Performance",
    FailedExam: "Failed Test(s)",
    NewAssessment: "New Assessment",
    OverAllGrade: "Over All Grade",
    AssessmentFor: "Assessment For",
    UnPaied: "UnPaied",
    Paied: "Paied",
    OverDue: "OverDue",
    ParentStudentInstallment: "Installment",
    EndedExam: "Old Exam",
    NewExams: "New Exams",
    EndedAssignment: "Old Assignment",
    NewAssignments: "New Assignments",
    SubjectPerformance: "Subject Performance",
    Contacts: "Contacts",
    PleaseAllowNotification: "Please Allow Notification",
    SendMessage: "Send Message",
    Total: "Total",
    Color: "Color",
    IsExam: "A different exam for each student",
    CodePhoneNumberHint: "Please Enter Code Phone Number EX.(+2)",
    Roles: "Roles",
    RoleScreenPermission: "Group Admin Permissions",
    IsShow: "Is Show",
    ScreenName: "Screen Name",
    ScheduleAndEvents: "Schedule And Events",
    MarkAsReadAll: "Mark As Read All",
    New: "New",
    AllStudents: "All Students",
    ActiveStudents: "Active Students",
    InActiveStudent: "InActive Students",
    StudentActiveLicenseCounts: "Student Active License Counts",
    ActiveLicense: "Active License",
    showChat: "show Chat",
    ClassSuperVisor: "Class Supervisor",
    ClassSuperVisors: "Class Supervisors",
    FullName: "Full Name",
    Parents: "Parents",
    ParentLoginInfo: "Parent Login Info",
    HaveSisBro: "Have SisBro",
    ClassSuperVisorAddEdit: "ClassSuper Visor Add Edit",
    GroupOfAdmin: "Group Of Admin",
    SetStudentAbsence: "Set Student Absence",
    StudentsPerformance: "Students Performance",
    ShowPerformance: "Show Performance",
    RegionFr: "Region Fr",
    RegionAr: "Region Ar",
    RegionEn: "Region En",
    NotReplayableChat: "Not Replayable Chat",
    Schedule: "Schedule",
    LiveSessions: "Live Sessions",
    Assignment: "Assignment",
    AbsentsInThisMonth: "Absents in month",
    YourScheduleInThisSemester: "Your schedule in this semester",
    EventsInThisSemester: "Events in this semester",
    GoToLink: "Go to link",
    UserFavorit: "User Favorit",
    Comments: "Comments",
    LiksUsers: "Liks Users",
    RateUsers: "Rate Users",
    MainData: "Main Data",
    Videos: "Videos",
    VideoEdit: "Video Edit",
    ViewCount: "View Count",
    Comment: "Comment",
    Rates: "Rates",
    Keyword: "Keyword",
    Expert: "Expert",
    Favorits: "Favorits",
    Competitions: "Competitions",
    TotalRate: "Total Rate",
    GotoVideoDetails: "Goto Video Details",
    UserVideo: "User Videos",
    AppUserFavirotVideos: "Favirot Videos",
    WinnersVideosLastCompetition: "The Winners Videos in Last Competitions",
    Monthly: "Monthly",
    Weekly: "Weekly",
    appUsers: "appUsers",
    experts: "experts",
    videos: "videos",
    countOfVideosInThisWeek: "count Of Videos In This Week",
    countOfVideosInThisMonth: "count Of Videos In This Month",
    TopWeeklyVideo: "Top Weekly Videos",
    TopMonthlyVideo: "Top Monthly Videos",
    ExpertEdit: "Expert_Edit",
    Address: "Address",
    AppUser: "AppUser",
    News: "News",
    NewsHeasline: "Arabic News Head line",
    NewsHeaslineEn: "English News Head line",
    NewsContentEn: "News English Content",
    NewsContentAr: "News Arrabic Content",
    NewsDate: "News Date",
    AuthorNameAr: "Author Arabic Name",
    AuthorNameEn: "Author English Name",
    AuthorName: "Author: ",
    MainImg: "Main Img",
    NewsGalleries: "News Galleries",
    EditNews: "Edit News",
    Country: "Country",
    City: "City",
    District: "District",
    Item: "Item",
    Submit: "Submit",

  },
  ar: {
    Country: "الدولة",
    City: "المدينة",
    District: "الحي",
    Submit: "اضافة",
    Item: "عنصر",
    News: "الاخبار",
    AuthorName: "الكاتب",
    EditNews: "تعديل الخبر",
    NewsHeasline: "عنوان الخبر باللغة العربية",
    NewsHeaslineEn: "عنوان الخبر باللغة الانجليزية",
    NewsContentEn: "الخبر باللغة الانجليزية",
    NewsContentAr: "الخبر باللغة العربية",
    AuthorNameAr: "اسم الكاتب باللغة العربية",
    AuthorNameEn: "اسم الكاتب باللغة الانجليزية",
    NewsDate: "تاريخ النشر",
    MainImg: "الصورة الرئسيسة للخبر",
    NewsGalleries: "معرض الصور",
    AppUser: "المستخدم",
    Address: "العنوان",
    ExpertEdit: "تعديل بيانات الخبير",
    UserVideo: "الفيديوهات",
    AppUserFavirotVideos: "الفيديوهات المفضلة",
    TopMonthlyVideo: "اعلي فيديوهات خلال هذ الشهر",
    TopWeeklyVideo: "اعلي فيديوهات خلال هذ الاسبوع",
    appUsers: "المستخدمين",
    experts: "الخبراء",
    videos: "الفيديوهات",
    countOfVideosInThisWeek: "عدد الفيديوهات الجديدة هذا الاسبوع",
    countOfVideosInThisMonth: "عدد الفيديوهات الجديدة هذا الشهر",
    Weekly: "اسبوعي",
    Monthly: "شهري",
    WinnersVideosLastCompetition: "الفديوهات الفائزة في المسابقات الاخيرة",

    GotoVideoDetails: "الذهاب الي تفاصيل الفيديو",
    TotalRate: "اجمالي التقييمات",
    Competitions: "المسابقات",
    Favorits: "الأعجابات",
    Expert: "خبير",
    Keyword: "كلمة دالة",
    Rates: "التقييمات",
    Comment: "التعليق",
    ViewCount: "عدد المشاهدات",
    VideoEdit: "تعديل  الفيديو",
    Videos: "الفيديوهات",
    MainData: "البيانات الاساسية",
    RateUsers: "التقييمات",
    LiksUsers: "الاعجابات",
    Comments: "التعليقات",
    UserFavorit: "مفضل لدى",
    GoToLink: "الذهاب الي الرابط",
    EventsInThisSemester: "احداث هذا الفصل الدراسي",
    YourScheduleInThisSemester: " جدولك هذا الفصل الدراسي",
    AbsentsInThisMonth: "غياب الشهر",
    Assignment: "الواجبات",
    LiveSessions: "الحصص اللايف",
    Schedule: "جدول الحصص",
    NotReplayableChat: "دردشة غير معادة",
    RegionEn: "المنطقة بالانجليزي",
    RegionAr: "المنطقة بالعربي",
    RegionFr: "المنطقة بالفرنساوي",
    ShowPerformance: "التقييم",
    StudentsPerformance: "تقييم الطلاب",
    SetStudentAbsence: "اخذ غياب الطلاب",
    GroupOfAdmin: "مجموعات المشرفين",
    ClassSuperVisorAddEdit: "اضافة وتعديل مشرف الفصل",
    HaveSisBro: "لديه اخ/اخت",
    ParentLoginInfo: "بيانات دخول ولي الامر",
    Parents: "اولياء الامور",
    FullName: "الاسم الكامل",
    ClassSuperVisors: "مشرفين غياب الفصول",
    ClassSuperVisor: "مشرف غياب الفصول",
    Reports: "التقارير",
    ActiveLicense: "الترخيص",
    StudentActiveLicenseCounts: "الطلبة المرخصين",
    ActiveStudents: "المرخصين",
    InActiveStudent: "الغير مرخصين",
    AllStudents: "الكل",
    New: "جديد",
    MarkAsReadAll: "تحديد الكل كمقروئة",
    ScheduleAndEvents: "جدول الحصص والاحداث",
    ScreenName: "اسم الصفحة",
    IsShow: "السماح بالرؤية",
    RoleScreenPermission: "صلاحيات الشاشة",
    Roles: "الصلاحيات",
    IsExam: "امتحان مختلف لكل طالب",
    PleaseAllowNotification: "من فضلك السماح بالاشعارات",
    SendMessage: "ارسال رسالة",
    Contacts: "المحادثات",
    NewExams: "اختبارات جديدة",
    EndedAssignment: "واجبات قديمة",
    NewAssignments: "واجبات جديدة",
    SubjectPerformance: "تقييم المادة",
    EndedExam: "اختبارات قديمة",
    ParentStudentInstallment: "الاقساط",
    OverDue: "تم انتهاء الموعد",
    Paied: "مدفوع",
    UnPaied: "غير مدفوع",
    AssessmentFor: "تقييم علي",
    OverAllGrade: "الدرجات ككل",
    OverAll: "الكل",
    NewAssessment: "تقييمات جديدة",
    FailedExam: "اختبارات لم يتم النجاح بها",
    OverAllPerformance: "التقييم ككل",
    Performance: "التقييم",
    For: "ل",
    TrueAndFalse: "صح او خطأ",
    ShowWithAnswers: "تصحيح الاجابات",
    Assessment: "تقييم الطلبة",
    StudentGrade: "درجة الطالب",
    ExamGrade: "درجة الاختبار",
    TeacherStudentsExamMarks: "تصحيح ودرجات الاختبارات",
    SubjectStageYear: "المادة الدراسية",
    NumberOfQuestions: "عدد الاسئلة",
    "pleasecheckyoure-mailanconfirmationsmssenttoyou": "يرجى التحقق من رسالة التأكيد المرسلة إليك",
    sendcodetomail: "إرسال الرمز إلى الهاتف",
    BackToLogin: "الي الرئيسية",
    "Pleaseenteryouremailaddressandwe'llsendyouInstructionsOnHowtoresetyourpassword": "يرجى إدخال عنوان بريدك الإلكتروني وسنرسل لك تعليمات حول كيفية إعادة تعيين كلمة المرور الخاصة بك",
    Recoveryourpassword: "استعادة كلمة المرور",
    ChangePassword: "اعادة تعيين كلمة المرور",
    SaveChanges: "حفظ",
    PasswordUpdated: "تم تحديث كلمة السر",
    YourPasswordHasBeenUpdatedSuccessfully: "لقد تم تحديث كلمة السر الخاصة بك بنجاح",

    isInTrip: "في رحلة ؟",
    Note: "ملحوظة",
    maxScore: "أقصى درجة",
    DaysOfWeek: "اليوم",
    PickupLocation: "العنوان",
    TIMEOFEND: "تاريخ الانتهاء",
    TIMEOFSTART: "تاريخ البدء",
    BusTrips: "رحلة الاتوبيس",
    BusTrip: "رحلة الاتوبيس",

    Pickup: "مكان استلام",
    Destination: "مكان التوصيل",
    Both: "الكل",
    Content: "المحتوى",
    SubjectExamRole: "قواعد الاختبارات ",
    Youarealreadyloggedin: "قد قمت بتسجيل الدخول بالفعل!",
    LoginAttempt: "محاولة تسجيل الدخول",
    showNoDataAvailable: "لا توجد بيانات متاحة ... يرجى المحاولة مرة أخرى",
    NoNotesAvailable: "لا توجد ملاحظات",
    showError: "حدث خطأ ما. أعد المحاولة من فضلك",
    Update: "تحديث",
    showUpdate: "تم التحديث بنجاح ...",
    Event: "الحدث",
    ColorCode: "لون الكود",
    Student: "طالب",
    "Route-Edit": "تعديل-المسار",
    AddEdit: "اضافة/تعديل",
    DescriptionOfAttachment: "وصف الملحقات",
    Accepted: "موافقة",
    Refused: "رفض",
    Pending: "قيد الانتظار",
    StudentChangeRouteRequestAddEdit: "تعديل/اضافة",
    Order: "طلب",
    StudentChangeRouteRequest: "طلب تغير مسار الحافلة",
    AllRoutesAndBusses: "خطوط النقل و الحافلات",
    studentBusOFF: "عدد الطلاب",
    StudentBusOffForWeek: "طلبات الغاء الحافلة ",
    AllChangeRouteRequestAccept: "عدد طلبات تغيير المسار المقبولة",
    AllChangeRouteRequestRefused: "عدد طلبات تغيير المسار المرفوضة",
    AllChangeRouteRequestPending: " عدد طلبات تغيير المسار المعلقة",
    AllChangeRouteRequest: " عدد طلبات تغيير الحافلة",
    UnPaidInstallmentCount: "الاقساط غير المسددة",
    PaidInstallmentCount: " الأقساط المدفوعة",
    AlltInstallmentCounts: "جميع الأقساط",
    InstallmentYearStartDate: "تاريخ بدء الاقساط",
    InstallmentYearEndDate: "تاريخ انتهاء الاقساط",
    EventsForWeek: " أحداث الأسبوع",
    ExamsForWeek: " الاختبارات لمدة أسبوع",
    drivers: "السائقين",
    teachers: "المعلمين",
    students: "الطلاب",
    parents: "الاباء",
    AllUsers: " جميع المستخدمين",
    ExamsAndEventsForWeek: " الاختبارات والفعاليات للأسبوع",
    StudentInstallmentCounts: "مجموع أقساط الطلاب",
    Filters: "البحث",
    RelaredAccounts: " الحسابات ذات الصلة",
    Data: " البيانات",
    SendChannelBook: "إرسال مراسلة",
    Send: "ارسال",
    RememberMe: "تذكرنى",
    WelcomeBack: "مرحبًا بك من جديد ، يرجى تسجيل الدخول إلى حسابك.",
    User: "مستخدم",
    StudentTeacherInstallments: "اقساط الطلاب/المستخدمين",
    CustomUser: "مستخدم معين",
    AllDriver: "كل السائقين",
    AllTeacher: "كل المدرسين",
    AllParent: "كل اولياء الامور",
    Parent: "والي امر",
    True: "نعم",
    False: "لا",
    Active: "فعال",
    Inactive: "موقوف",
    ExamCode: "كود الاختبار",
    Pdf: "بي دي إف",
    Video: "فيديو",
    Image: "صورة",
    Document: "ملف",
    Logout: "خروج",
    ThisItemAlreadyExist: "هذا البند موجود من قبل",
    AlreadyExist: "موجود من قبل",
    Edit: "تعديل",
    Material: "ملحقات",
    MaterialType: "نوع الماتيريال",
    All: "الكل",
    ClassTeacherSubject: "الفصول",
    AddSubjects: "اضافة مواد",
    EndDate: "نهاية المدة",
    Sender: "المرسل",
    Message: "الرسالة",
    MessageType: "نوع الرسالة",
    IsReplyable: "يمكن الرد",
    Recevier: "المرسل اليه",
    ChannelBook: "المراسلات",
    ChannelBookType: "انواع المراسلات",
    ChannelBookAddEdit: "المراسلات اضافة/ تعديل",
    BusTracking: "تتبع الاتوبيسات",
    ProjectTitle: "مدرستك",
    Home: "الرئيسية",
    AddNew: "أضافة",
    Action: "العمليات",
    NameAr: "الاسم بالعربي",
    CommingMeetings: "الجلسات القادمة",
    ClassName: "الفصل",
    CreatorName: "أسم المستخدم",
    MeetingsList: "قائمة الجلسات",
    IsPublic: "عام",
    HasRecord: "يوجد تسجيلات",
    StudentMeetingsList: "قائمة الجلسات للطلبة",
    NameEn: "الاسم بالانجليزي",
    NameFr: "الاسم بالفرانساوي",
    AddressAr: " العنوان بالعربي ",
    AddressEn: "العنوان بالانجليزي",
    AddressFr: "العنوان بالفرانساوي",
    NewMaterial: "اضافة ملحق",
    ViewMaterial: "الملحقات",
    NoteAr: "ملاحظة بالعربي",
    NoteEn: "ملاحظة بالانجليزي",
    NoteFr: "ملاحظة بالفرانساوي",
    StudentUserInstallment: "اقساط الطلاب/المستخدمين",
    AboutUsAr: " معلومات عنا بالعربي",
    AboutUsEn: " معلومات عنا بالانجليزي",
    AboutUsFr: " معلومات عنا بالفرانساوي",
    Logo: "لوجو",
    SendVertificationCode: "ارسال رمز التعريف",
    Areyousureyouwantdeletethisrecord: "هل أنت متأكد أنك تريد حذف هذا السجل",
    AreyousureyouwantActivethisStudent: "هل أنت متأكد أنك تريد تفعيل هذا الطالب",
    Cancel: "الغاء",
    Delete: "حذف",
    PhoneNumber: "رقم الهاتف",
    VertificationCode: "رمز التعريف",
    Vertify: "تاكيد الرمز",
    MainInfo: "البيانات الاساسية",
    LoginInfo: "بيانات الدخول",
    Save: "حفظ",
    Email: "البريد الاكتروني",
    Password: "الرقم السري",
    Buses: "الاتوبيسات",
    Bus: "اتوبيس",
    Attachment: "الملحقات",
    Installments: "الأقساط",
    Installment: "ألقسط",
    Stage: "المرحلة",
    StageYear: "السنة",
    Class: "الفصل",
    Age: "السن",
    Subjects: "المواد",
    NewAttachment: "ملحق جديد",
    AddAttachment: "اضافة ملحق",
    Description: "الوصف",
    URL: "الرابط",
    File: "الملف",
    NewBus: "اضافة اتوبيس",
    AddBus: "اضافة اتوبيس",
    ModelAr: "النموذج بالعربي",
    ModelEn: "النموذج بالانجليزي",
    ModelFr: "النموذج بالفرنساوي",
    IsActive: "فعال",
    InstallmentTypeEn: "اسم نوع القسط بالعربي",
    AddInstallment: "أضافة قسط",
    NewInstallment: "قسط جديد",
    PayDate: "تاريخ الدفع",
    IsPaid: "الدفع",
    Routes: "الطرق",
    Users: "المستخدمين",
    Students: "الطلاب",
    YouAreAboutToAddThisAssessment: "أنت على وشك إضافة هذا التقييم?",
    Teachers: "المعلمين",
    Drivers: "السائقين",
    ExaminationManagement: "أدارة الاختبارات",
    QuestionBank: "بنك الاسئلة",
    ExamRule: "قواعد الاختبار",
    ExamGeneration: "انشاء الاختبار",
    Setting: "الاعدادات",
    SchoolInfo: "بيانات المدرسة",
    AssessmentType: "نوع التقيم",
    BusSuperVisors: "مشرفين الاتوبيسات",
    Language: "اللغة",
    GradeType: "نوع الدرجة",
    PleaseSelectAbsenceStudents: "يرجى تحديد الطلاب الغائبين",
    ExamType: "نوع الاختبار",
    QuestionLevel: "مستوي السؤال",
    TotalAttendees: "عدد الحاضرين",
    TotalAbsence: "عدد الغائبين",
    QuestionType: "نوع السؤال",
    Stages: "المراحل الدراسية",
    StageYears: "سنين المراحل الدراسية",
    InstallmentType: "نوع القسط",
    Name: "الاسم",
    FixedExam: "امتحان ثابت",
    RandomExam: "امتحان عشوائي",
    Reset: "اعادة",
    UserWithSamePhoneNumberExist: "يوجد مستخدم بنفس رقم الهاتف",
    SomethingWentWrong: "حدث خطأ ما",
    CodeIsNotExist: "رمز التعريف خطأ",
    Welcome: "مرحبا بك",
    Date: "التاريخ",
    ClassSessionName: "الحصة",
    SubjectName: "المادة",
    FromDate: "من",
    ToDate: "الي",
    ClassSession: "الحصة",
    ClassSchedule: "جدول الحصص",
    ClassScheduleAddEdit: "جدول الحصص اضافة/ تعديل",
    Status: "الحالة",
    Modules: "النماذج",
    Newlanguage: "لغة جديدة",
    AddLanguage: "اضافة لغة",
    NewModule: "وحدة جديد",
    AddModule: "اضافة وحدة",
    ThisModuleAlreadyExists: "هذه الوحدة موجودة بالفعل",
    ThisSubjectAlreadyExists: "هذه المادة موجودة بالفعل",
    ThisDriverAlreadyExists: "هذه السائق موجودة بالفعل",
    Module: "الوحدة",
    Route: "الطريق",
    Details: "التفاصيل",
    AddRouteDetails: "اضافة تفاصيل الطريق",
    Longitude: "خط الطول",
    Latitude: "خط العرض",
    Region: "المنطقة",
    NewRouteDetails: "تقاصيل طريق جديدة",
    Model: "النموذج",
    BusSupervisor: "مشرف الاتوبيس",
    Driver: "السائق",
    DriverAddEdit: "السائق اضافة/ تعديل",
    AddDriver: "اضافة سائق",
    NewDriver: "سائق جديد",
    Classes: "الفصول",
    NewSubject: "مادة جديدة",
    Subject: "المادة",
    Teacher: "المعلم",
    NewClass: "فصل جديد",
    TeacherAddEdit: "المعلم اضافة/ تعديل",

    AddSubject: "اضافة مادة",
    SubjectMaxScore: "درجة المادة",
    StudentExam: "اختبارات الطالب",
    QuestionNum: "رقم السؤال",
    QuestionAr: "السؤال بالعربي",
    QuestionEn: "السؤال بالانجليزي",
    QuestionFr: "السؤال بالفرانساوي",
    NewQuestion: " سؤال جديد",
    Question: "السؤال",
    Answers: "الاجابات",
    AnswerAr: "الاجابة بالعربي",
    AnswerEn: "الاجابة بالنجليزي",
    AnswerFr: "الاجابة بالفرانساوي",
    IsRight: "صحيح",
    Addanswer: "اضافة اجابة",
    DescriptionAr: "الوصف بالعربي",
    DescriptionEn: "الوصف بالانجليزي",
    DescriptionFr: "الوصف بالفرانساوي",
    Download: "تحميل",
    Type: "النوع",
    AddRule: "اضافة قاعدة",
    NewRule: "قاعدة جديدة",
    RuleInfo: "بيانات القاعدة",
    ExamStartDate: "تاريخ الاختبار",
    Exams: "الاختبارات",
    TimeOfExamInMinutes: "مدة الاختبار بالدقائق",
    Generate: "انشاء",
    ExamMainInfo: "بيانات الاختبار",
    GenerateQuestions: "انشاء الاسئلة",
    Text: "مقالي",
    TrueFalse: "صح او خطأ",
    MultiChoose: "متعدد الاختيارات",
    TimeInMinute: "المدة بالدقائق",
    Weight: "الدرجة",
    TypeAr: "النوع بالعربي",
    TypeEn: "النوع بالانجليزي",
    TypeFr: "النوع بالفرانساوي",
    UPDATE: "تعديل",
    Amount: "المبلغ",
    InstallmentDate: "تاريخ القسط",
    DeadLine: "الموعد النهائي",
    LevelAr: "المستوي بالعربي",
    LevelEn: "المستوي بالانجليزي",
    LevelFr: "المستوي بالفرانساوي",
    EventType: "نوع الحدث",
    Events: "الاحداث",
    SchoolArchitecture: "اساسيات المدرسة",
    UserType: "نوع المستخدم",
    RelatedAccounts: "الحسابات المرتبطة",
    UserManagment: "ادارة المستخدمين",
    SubjectManagment: "ادارة المواد",
    StageYearSubjects: "مواد المرحلة الدراسية",
    SubjectModules: "ملحقات المادة",
    BuseManagment: "ادارة الاتوبيسات",
    EventManagment: "ادارة الاحداث",
    InstallmentManagment: "ادارة الاقساط",
    Grade: "الدرجة",
    Success: "نجاح",
    CreatedSuccessfully: "تم الاضافة بنجاح",
    UpdatedSuccessfully: "تم التعديل بنجاح",
    Error: "خطا",
    CreatedFailed: "لم يتم الاضافة",
    UpdatedFailed: "لم يتم التعديل",
    DeletedSuccessfully: "تم الحذف بنجاح",
    DeleteFailed: "لم يتم الحذف",
    Confirm: "تاكيد",
    DeleteConfirmText: "هل انت متاكد من انك تريد الحذف",
    ErrorTryLater: "حث خطا ما برجاء المحاولة في وقت لاحق",
    Dashboard: "احصائيات",
    eCommerce: "احصائيات",
    StudentExamAnswer: "اجابات الطالب",
    UpdateImage: "اضافة الصورة",
    RemoveImage: "حذف الصورة",
    Total: "الاجمالي",
    Color: "اللون",
    showChat: "عرض الرسائل",
    CodePhoneNumberHint: "برجاء ادخال كود البلد (+2)",
    Apps: "Apps",
    Todo: "Machen",
    Chat: "Plaudern",
    Calendar: "Kalender",
    FullCalendar: "Calendrier completVollständiger Kalender",
    SimpleCalendar: "Einfacher Kalender",
    Shop: "Geschäft",
    Cart: "Wagen",
    WishList: "Wunschzettel",
    Checkout: "Auschecken",
    UI: "UI",
    DataList: "Datenliste",
    ListView: "Listenansicht",
    ThumbView: "Daumenansicht",
    Grid: "Gitter",
    Vuexy: "Vuexy",
    Vuesax: "Vuesax",
    Tailwind: "Tailwind",
    Colors: "Farben",
    Card: "Karte",
    Basic: "Basic",
    Statistics: "Statistiken",
    Analytics: "Analytics",
    CardActions: "Kartenaktionen",
    CardColors: "Kartenfarben",
    Table: "Tabelle",
    agGridTable: "agGrid-Tabelle",
    Components: "Komponenten",
    Alert: "Warnen",
    Avatar: "Benutzerbild",
    Badge: "Abzeichen",
    Breadcrumb: "Breadcrumb",
    Button: "Taste",
    ButtonGroup: "Schaltflächengruppe",
    Chip: "Chip",
    Collapse: "Zusammenbruch",
    Dialogs: "Dialoge",
    Divider: "Teiler",
    DropDown: "Dropdown-Liste",
    List: "Liste",
    Loading: "Wird geladen",
    Navbar: "Navbar",
    Notifications: "Benachrichtigungen",
    Pagination: "Seitennummerierung",
    Popup: "Pop-up",
    Progress: "Fortschritt",
    Sidebar: "Seitenleiste",
    Slider: "Schieberegler",
    Tabs: "Tabs",
    Tooltip: "QuickInfo",
    Upload: "Hochladen",
    FormsAndTable: "Formulare und Tabelle",
    FormElements: "Formularelemente",
    Select: "Wählen",
    Switch: "Schalter",
    Checkbox: "Ankreuzfeld",
    Radio: "Radio",
    Input: "Eingang",
    NumberInput: "Nummer eingeben",
    Textarea: "Textbereich",
    FormLayouts: "Formularlayouts",
    FormWizard: "Formzauberer",
    FormValidation: "Formularvalidierung",
    FormInputGroup: "Formulareingabegruppe",
    Pages: "Seiten",
    Authentication: "Authentifizierung",
    Login: "دخول",
    Register: "نسجيل",
    ForgotPassword: "نسيت كلمة المرور؟",
    ResetPassword: "اعادة تعيين كلمة المرور",
    LockScreen: "Bildschirm sperren",
    Miscellaneous: "Verschiedenes",
    ComingSoon: "Demnächst",
    404: "404",
    500: "500",
    NotAuthorized: "Nicht berechtigt",
    Maintenance: "Instandhaltung",
    Profile: "صفحتي",
    Invoice: "Rechnung",
    FAQ: "FAQ",
    Search: "بحث",
    KnowledgeBase: "Wissensbasis",
    ChartsAndMaps: "Diagramme und Karten",
    Charts: "Diagramme",
    ApexCharts: "Apex Charts",
    chartjs: "chartjs",
    echarts: "echarts",
    chartist: "chartist",
    GoogleMap: "Google Karte",
    Extensions: "Erweiterungen",
    QuillEditor: "Quill-Editor",
    DragAndDrop: "Ziehen und loslassen",
    Datepicker: "Datumsauswahl",
    DatetimePicker: "Datum / Uhrzeit-Auswahl",
    AccessControl: "Zugangskontrolle",
    I18n: "I18n",
    Carousel: "Karussell",
    Clipboard: "Zwischenablage",
    ContextMenu: "Kontextmenü",
    StarRatings: "Sterne Bewertungen",
    Autocomplete: "Autovervollständigung",
    Tree: "Baum",
    Import: "Einführen",
    Export: "Export",
    ExportSelected: "Auswahl exportieren",
    Others: "Andere",
    MenuLevels: "Menüebenen",
    MenuLevel2p1: "Menüebene 2.1",
    MenuLevel2p2: "Menüebene 2.2",
    MenuLevel3p1: "Menüebene 3.1",
    MenuLevel3p2: "Menüebene 3.2",
    DisabledMenu: "Deaktiviertes Menü",
    Support: "Unterstützung",
    Documentation: "Dokumentation",
    RaiseSupport: "Unterstützung erheben",
    demoTitle: "Kartentitel",
    demoText: "Kuchen Sesam Schnaps Cupcake Lebkuchen Dänisch Ich liebe Lebkuchen. Apfelkuchen-Torte-Jujuben chupa chups muffin halvah Lutscher. Schokoladenkuchenhaferkuchen Tiramisu-Marzipanzuckerpflaume. Donut süße Kuchen Hafer Kuchen Dragée Obstkuchen Zuckerwatte Zitrone Tropfen.",
    TermsOfUse: "Gebruiks voor waarden",
    PrivacyPolicy: "Privacy beleid",
    Imprints: "Opdrukken"
  },
  fr: {
    showNoDataAvailable: "Aucune donnée disponible ... Veuillez réessayer",
    NoNotesAvailable: "Aucune note disponible",
    SorryYouAccountisNotActive: "Désolé .. Votre compte n'est pas actif",
    WelcomeMsg: "Bienvenue à nouveau, veuillez vous connecter à votre compte.",
    Update: "Mettre à jour",
    showError: "Une erreur s'est produite ... Veuillez réessayer",
    showUpdate: "Enregistrement mis à jour avec succès ...",
    showCheckEmail: "Veuillez vérifier votre e-mail pour un nouveau mot de passe ...",
    showPasswordChanged: "Mot de passe modifié ...",
    DescriptionOfAttachment: "Description Of Attachment",
    LoginAttempt: "Login Attempt",
    Youarealreadyloggedin: "You are already logged in!",
    ModelAr: "Modèle arabe",
    Password: "Mot de passe",
    RememberMe: "Souviens-toi de moi",
    WelcomeBack: "Bienvenue à nouveau, veuillez vous connecter à votre compte.",
    Dashboard: "Tableau de bord",
    eCommerce: "commerce électronique",
    Apps: "Applications",
    Todo: "Faire",
    Chat: "Bavarder",
    Calendar: "Calendrier",
    FullCalendar: "Calendrier complet",
    SimpleCalendar: "Calendrier simple",
    Shop: "Boutique",
    Cart: "Chariot",
    WishList: "Liste de souhaits",
    Checkout: "Check-out",
    UI: "UI",
    DataList: "Liste de données",
    ListView: "Voir la liste",
    ThumbView: "Thumb View",
    Grid: "la grille",
    Vuexy: "Vuexy",
    Vuesax: "Vuesax",
    Tailwind: "Tailwind",
    Colors: "Couleurs",
    Card: "Carte",
    Basic: "De base",
    Statistics: "Statistiques",
    Analytics: "Analytique",
    CardActions: "Actions de carte",
    CardColors: "Couleurs de la carte",
    Table: "Table",
    agGridTable: "tableau agGrid",
    Components: "Composants",
    Alert: "Alerte",
    Avatar: "Avatar",
    Badge: "Badge",
    Breadcrumb: "Breadcrumb",
    Button: "Bouton",
    ButtonGroup: "Groupe de boutons",
    Chip: "Puce",
    Collapse: "Effondrer",
    Dialogs: "Dialogues",
    Divider: "Diviseur",
    DropDown: "Menu déroulant",
    List: "liste",
    Loading: "Chargement",
    Navbar: "Barre de navigation",
    Notifications: "Les notifications",
    Pagination: "Pagination",
    Popup: "Apparaitre",
    Progress: "Le progrès",
    Sidebar: "Barre latérale",
    Slider: "Curseur",
    Tabs: "Onglets",
    Tooltip: "Info-bulle",
    Upload: "Télécharger",
    FormsAndTable: "Formulaires et tableau",
    FormElements: "Éléments de formulaire",
    Select: "Sélectionner",
    Switch: "Commutateur",
    Checkbox: "Case à cocher",
    Radio: "Radio",
    Input: "Contribution",
    NumberInput: "Nombre d'entrée",
    Textarea: "Textarea",
    FormLayouts: "Dispositions de formulaire",
    FormWizard: "Assistant de formulaire",
    FormValidation: "Validation du formulaire",
    FormInputGroup: "Groupe de saisie de formulaire",
    Pages: "Pages",
    Authentication: "Authentification",
    Login: "S'identifier",
    Register: "registre",
    ForgotPassword: "Mot de passe oublié",
    ResetPassword: "réinitialiser le mot de passe",
    LockScreen: "Écran verrouillé",
    Miscellaneous: "Divers",
    ComingSoon: "Arrive bientôt",
    Error: "Erreur",
    404: "404",
    500: "500",
    NotAuthorized: "Pas autorisé",
    Maintenance: "Entretien",
    Profile: "Profil",
    Invoice: "Facture d'achat",
    FAQ: "FAQ",
    Search: "Chercher",
    KnowledgeBase: "Base de connaissances",
    ChartsAndMaps: "Graphiques Et Cartes",
    Charts: "Graphiques",
    ApexCharts: "Apex Charts",
    chartjs: "chartjs",
    echarts: "echarts",
    chartist: "chartist",
    GoogleMap: "Google Carte",
    Extensions: "Extensions",
    QuillEditor: "Éditeur de piquants",
    DragAndDrop: "Drag & Drop",
    Datepicker: "Sélecteur de date",
    DatetimePicker: "Sélecteur de date / heure",
    AccessControl: `Contrôle d'accès`,
    I18n: "I18n",
    Carousel: "Carrousel",
    Clipboard: "Presse-papiers",
    ContextMenu: "Menu contextuel",
    StarRatings: "Classement par étoiles",
    Autocomplete: "Terminé automatiquement",
    Tree: "Arbre",
    Import: "Importation",
    Export: "Exportation",
    ExportSelected: "Exporter sélectionné",
    Others: "Autre",
    MenuLevels: "Niveaux de menu",
    MenuLevel2p1: "Niveau de menu 2.1",
    MenuLevel2p2: "Niveau de menu 2.2",
    MenuLevel3p1: "Niveau de menu 3.1",
    MenuLevel3p2: "Niveau de menu 3.2",
    DisabledMenu: "Menu désactivé",
    Support: "Soutien",
    Documentation: "Documentation",
    RaiseSupport: "Augmenter le soutien",
    demoTitle: "Titre de la carte",
    demoText: `Le gâteau au sésame s'enclenche au petit pain au pain d'épices danois J'adore le pain d'épices. Tarte aux pommes et jujubes chupa chups muffin halvah sucette. Tiramisu gâteau au chocolat gâteau d'avoine tiramisu prune sucre. Donut tarte sucrée gâteau à l'avoine dragée gouttes de fruits gâteau au citron en barbe à papa.`,
    TermsOfUse: "Conditions d'utilisation",
    PrivacyPolicy: "Politique de confidentialité	",
    Imprints: "Empreintes"
  },
  pt: {
    DescriptionOfAttachment: "Description Of Attachment",
    Dashboard: "painel de controle",
    eCommerce: "comércio eletrônico",
    Apps: "APPS",
    Todo: "Façam",
    Chat: "Bate-papo",
    Email: "O email",
    Calendar: "Calendário",
    FullCalendar: "Calendário Completo",
    SimpleCalendar: "Calendário Simples",
    Shop: "fazer compras",
    Cart: "Carrinho",
    WishList: "Lista de Desejos",
    Checkout: "Confira",
    UI: "UI",
    DataList: "Lista de dados",
    ListView: "Exibição de lista",
    ThumbView: "Thumb View",
    Grid: "Grade",
    Vuexy: "Vuexy",
    Vuesax: "Vuesax",
    Tailwind: "Tailwind",
    Colors: "Cores",
    Card: "Cartão",
    Basic: "Basic",
    Statistics: "Estatisticas",
    Analytics: "Analytics",
    CardActions: "Ações do Cartão",
    CardColors: "Cores do cartão",
    Table: "Mesa",
    agGridTable: "Tabela AgGrid",
    Components: "Componentes",
    Alert: "Alerta",
    Avatar: "Avatar",
    Badge: "Crachá",
    Breadcrumb: "Breadcrumb",
    Button: "Botão",
    ButtonGroup: "Grupo de botões",
    Chip: "Lasca",
    Collapse: "Colapso",
    Dialogs: "Diálogos",
    Divider: "Divisor",
    DropDown: "Suspenso",
    List: "Lista",
    Loading: "Carregando",
    Navbar: "Navbar",
    Notifications: "Notificações",
    Pagination: "Paginação",
    Popup: "Aparecer",
    Progress: "Progresso",
    Sidebar: "Barra Lateral",
    Slider: "Controle Deslizante",
    Tabs: "Guias",
    Tooltip: "dica de ferramenta",
    Upload: "Envio",
    FormsAndTable: "Formulários e tabela",
    FormElements: "Elementos do formulário",
    Select: "Selecione",
    Switch: "Interruptor",
    Checkbox: "Caixa de seleção",
    Radio: "Rádio",
    Input: "Entrada",
    NumberInput: "Entrada numérica",
    Textarea: "Textarea",
    FormLayouts: "Layouts de formulário",
    FormWizard: "Assistente de Formulário",
    FormValidation: "Validação de Formulário",
    FormInputGroup: "Grupo de entrada de formulário",
    Pages: "PÁGINAS",
    Authentication: "Autenticação",
    Login: "Entrar",
    Register: "Registo",
    ForgotPassword: "Esqueceu A Senha",
    ResetPassword: "Redefinir Senha",
    LockScreen: "Tela de bloqueio",
    Miscellaneous: "Diversos",
    ComingSoon: "Em breve",
    Error: "Erro",
    404: "404",
    500: "500",
    NotAuthorized: "Não autorizado",
    Maintenance: "Manutenção",
    Profile: "Perfil",
    Invoice: "Fatura",
    FAQ: "Perguntas frequentes",
    Search: "Procurar",
    KnowledgeBase: "Base de Conhecimento",
    ChartsAndMaps: "Gráficos E Mapas",
    Charts: "Gráficos",
    ApexCharts: "Apex Charts",
    chartjs: "chartjs",
    echarts: "echarts",
    chartist: "chartist",
    GoogleMap: "Google Mapa",
    Extensions: "Extensão",
    QuillEditor: "Quill-Editor",
    DragAndDrop: "Arraste e solte",
    Datepicker: "Datepicker",
    DatetimePicker: "Seletor de data e hora",
    AccessControl: "Controle de acesso",
    I18n: "I18n",
    Carousel: "Carrossel",
    Clipboard: "Prancheta",
    ContextMenu: "Menu contextual",
    StarRatings: "Classificações por estrelas",
    Autocomplete: "autocompletar",
    Tree: "Árvore",
    // "Import/Export": 'Importar/Exportar',
    Import: "Importar",
    Export: "Exportar",
    ExportSelected: "Exportar Selecionado",
    Others: "Outras",
    MenuLevels: "Níveis de Menu",
    MenuLevel2p1: "Nível de menu 2.1",
    MenuLevel2p2: "Nível de menu 2.2",
    MenuLevel3p1: "Nível de menu 3.1",
    MenuLevel3p2: "Nível de menu 3.2",
    DisabledMenu: "Menu desativado",
    Support: "Apoio, suporte",
    Documentation: "Documentação",
    RaiseSupport: "Levantar Suporte",
    demoTitle: "Título do cartão",
    demoText: `O sésamo do bolo agarra dinamarquês do pão-de-espécie do queque eu amo o pão-de-espécie. Torta de torta de maçã jujuba chupa chups muffin halvah pirulito. Ameixa do açúcar do maçapão do tiramisu do bolo da aveia do bolo de chocolate. Bolo de aveia de torta doce rosquinha dragée fruitcake algodão doce gotas de limão.`
  }
};
