/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import Router from "vue-router";
import store from "@/store/store.js";

Vue.use(Router);

const router = new Router({
  // mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: "",
      component: () => import("./layouts/main/Main.vue"),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: "/",
          name: "home",
          component: () => import("./views/Home.vue"),
          meta: {
            rule: "anonymous"
          }
        },


        {
          path: "/dashboard/ecommerce",
          name: "dashboard-ecommerce",
          component: () => import("./views/DashboardECommerce.vue"),
          meta: {
            rule: "User"
          }
        },
         //--------------------------------------------------
        //--------------------------------------------------
        //Competition
        {
          path: "/Competition",
          name: "Competition",
          component: () => import("./views/Competition/Competitions.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Competitions", url: "/Competition", active: true }
            ],
            rule: "Admin",
            pageTitle: "Competitions"
          }
        },
        //--------------------------------------------------
        //--------------------------------------------------
        //Video
        {
          path: "/Video",
          name: "Video",
          component: () => import("./views/Video/Videos.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Video", url: "/Video", active: true }
            ],
            rule: "AdminExpert",
            pageTitle: "Video"
          }
        },
        {
          path: "/Video/Video_Edit/:ID?",
          name: "Video_Edit",
          component: () => import("./views/Video/Video_Edit.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "VideoEdit", url: "/Video/Video_Edit/", active: true }
            ],
            rule: "AdminExpert",
            pageTitle: "Video_Edit"
          }
        },

        {
          path: "/News",
          name: "News",
          component: () => import("./views/News/News.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "News", url: "/News", active: true }
            ],
            rule: "AdminExpert",
            pageTitle: "News"
          }
        },

        {
          path: "/News/News_Edit/:ID?",
          name: "News_Edit",
          component: () => import("./views/News/News_Edit.vue"),
          meta: {
            breadcrumb: [
              { title: "Home", url: "/" },
              { title: "NewsEdit", url: "/News/News_Edit/", active: true }
            ],
            rule: "AdminExpert",
            pageTitle: "News_Edit"
          }
        },

          {
            path: "/User/AppUser",
            name: "AppUser",
            component: () => import("./views/user/AppUser/AppUser.vue"),
           meta: {
             breadcrumb: [
                { title: "Home", url: "/" },
                { title: "AppUser", url: "/User/AppUser/", active: true }
              ],
              rule: "anonymous",
              pageTitle: "AppUser"
            }
          },
          {
            path: "/User/AppUser/AppUser_Edit/:ID?",
            name: "AppUser-Edit",
            component: () => import("./views/user/AppUser/AppUser_Edit.vue"),
           meta: {
             breadcrumb: [
                { title: "Home", url: "/" },
                { title: "", url: "/User/AppUser/", active: true },
                { title: "AppUser_Edit", active: true }
              ],
              rule: "Admin",
              pageTitle: "AppUser_Edit"
            }
          },

          {
            path: "/Settings/Country",
            name: "Country",
            component: () => import("./views/Settings/Country/Country.vue"),
           meta: {
             breadcrumb: [
                { title: "Home", url: "/" },
                { title: "Country", url: "/Settings/Country/", active: true }
              ],
              rule: "anonymous",
              pageTitle: "Country"
            }
          },
          {
            path: "/Settings/City",
            name: "City",
            component: () => import("./views/Settings/City/City.vue"),
           meta: {
             breadcrumb: [
                { title: "Home", url: "/" },
                { title: "City", url: "/Settings/City/", active: true }
              ],
              rule: "anonymous",
              pageTitle: "City"
            }
          },
          {
            path: "/Settings/District",
            name: "District",
            component: () => import("./views/Settings/District/District.vue"),
           meta: {
             breadcrumb: [
                { title: "Home", url: "/" },
                { title: "District", url: "/Settings/District/", active: true }
              ],
              rule: "anonymous",
              pageTitle: "District"
            }
          },



        //-----------------Experts---------------------------------
        {
          path: "/User/Expert",
          name: "Expert",
          component: () => import("./views/user/Expert/Experts.vue"),
         meta: {
           breadcrumb: [
              { title: "Home", url: "/" },
              { title: "Expert", url: "/User/Expert/", active: true }
            ],
            rule: "anonymous",
            pageTitle: "Expert"
          }
        },
        {
          path: "/User/Expert/Expert_Edit/:ID?",
          name: "Expert-Edit",
          component: () => import("./views/user/Expert/Expert_Edit.vue"),
         meta: {
           breadcrumb: [
              { title: "Home", url: "/" },
              { title: "", url: "/User/Expert/", active: true },
              { title: "ExpertEdit", active: true }
            ],
            rule: "Admin",
            pageTitle: "ExpertEdit"
          }
        },

        //--------------------------------------------------

        //----------------------------------------------------------------------------------
        {
          path: "/dashboard/ecommerce",
          name: "dashboard-ecommerce",
          component: () => import("./views/DashboardECommerce.vue"),
          meta: {
            rule: "User"
          }
        }
      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: "",
      component: () => import("@/layouts/full-page/FullPage.vue"),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================

        {
          path: "/pages/login",
          name: "page-login",
          component: () => import("@/views/pages/login/Login.vue"),
          meta: {
            rule: "anonymous",
            pageTitle: "Login"
          }
        },
        {
          path: "/pages/register",
          name: "page-register",
          component: () => import("@/views/pages/register/Register.vue"),
          meta: {
            rule: "User"
          }
        },
        {
          path: "/pages/ForgotPassword",
          name: "ForgotPassword",
          component: () => import("@/views/pages/ForgotPassword.vue"),
          meta: {
            rule: "anonymous",
            pageTitle: "Forgot Password"
          }
        },
        {
          path: "/pages/reset-password",
          name: "page-reset-password",
          component: () => import("@/views/pages/ResetPassword.vue"),
          meta: {
            rule: "User"
          }
        },
        {
          path: "/ChangePassword",
          name: "ChangePassword",
          component: () => import("@/views/pages/ChangePassword.vue"),
          meta: {
            rule: "ParentTeacher"
          }
        },
        {
          path: "/pages/lock-screen",
          name: "page-lock-screen",
          component: () => import("@/views/pages/LockScreen.vue"),
          meta: {
            rule: "User"
          }
        },
        {
          path: "/pages/comingsoon",
          name: "page-coming-soon",
          component: () => import("@/views/pages/ComingSoon.vue"),
          meta: {
            rule: "User"
          }
        },
        {
          path: "/pages/error-404",
          name: "page-error-404",
          component: () => import("@/views/pages/Error404.vue"),
          meta: {
            rule: "User"
          }
        },
        {
          path: "/pages/error-500",
          name: "page-error-500",
          component: () => import("@/views/pages/Error500.vue"),
          meta: {
            rule: "User"
          }
        },
        {
          path: "/pages/not-authorized",
          name: "page-not-authorized",
          component: () => import("@/views/pages/NotAuthorized.vue"),
          meta: {
            rule: "User"
          }
        },
        {
          path: "/pages/maintenance",
          name: "page-maintenance",
          component: () => import("@/views/pages/Maintenance.vue"),
          meta: {
            rule: "User"
          }
        }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: "*",
      redirect: "/pages/error-404"
    }
  ]
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

router.beforeEach((to, from, next) => {

  const publicPages = ["/pages/login", "/pages/ForgotPassword"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("userInfo");
   debugger;
  // redirect to login page

  if (authRequired && !loggedIn ) {
    store.commit("REMOVE_USER_STORE", null, { root: true });
         next(to.path);
  } else {
    next();
  }

});

export default router;
